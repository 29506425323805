<template>
  <footer class="bg-black text-white py-10 px-5 lg:px-20">
    <div
      class="max-w-6xl mx-auto flex flex-col lg:flex-row justify-between items-center"
    >
      <!-- Logo -->
      <div class="mb-8 lg:mb-0">
        <img src="../assets/LOGO_WHITE.png" alt="Logo" class="h-32" />
      </div>

      <!-- Links de Navegação -->
      <div class="flex flex-col items-center justify-between space-y-4">
        <a href="/" class="hover:text-gray-300">HOME</a>
        <a href="/sobre" class="hover:text-gray-300">SOBRE NÓS</a>
        <a href="/servicos" class="hover:text-gray-300">SERVIÇOS</a>
        <a href="/projetos" class="hover:text-gray-300">PROJETOS</a>
        <a href="/contato" class="hover:text-gray-300">CONTATO</a>
      </div>

      <!-- Contato e Mídias Sociais -->
      <div class="flex flex-col lg:flex-row items-center lg:items-end">
        <!-- Detalhes de Contato -->
        <div class="mb-6 lg:mb-0 lg:mr-10 text-center lg:text-right">
          <p>R. das Orquídeas, 737, sala 315 - Jardim Pompeia</p>
          <p>Indaiatuba - SP</p>
          <p>13345-040</p>
          <a href="mailto:contato@studio9.com.br" class="hover:text-gray- 300"
            >contato@studio9.com</a
          >
          <p>(19) 3834-1202</p>
          <p>(19) 97103-9085</p>
        </div>
        <!-- Botões de Mídia Social e Contato -->
        <!-- <div class="flex justify-center lg:justify-start">
          <a href="#" class="mx-2 hover:text-gray-300">
          </a>
          <a href="#" class="mx-2 hover:text-gray-300">
          </a>
          <a href="#" class="mx-2 hover:text-gray-300">
          </a>
          <a href="#" class="mx-2 hover:text-gray-300">
          </a>
          <a href="#" class="mx-2 hover:text-gray-300">
          </a>
        </div> -->

        <!-- Botão de Contato -->
        <a
          href="/contato"
          class="mb-12 lg:mt-0 border border-white rounded px-6 py-2 hover:bg-white hover:text-black transition-colors"
        >
          ENTRE EM CONTATO
        </a>
      </div>
    </div>

    <!-- Links de Política -->
    <div class="mt-10 border-t border-gray-700 pt-5 text-center">
      <a href="/termos" class="hover:text-gray-300">TERMO & CONDIÇÕES</a>
      <a href="/privacidade" class="ml-4 hover:text-gray-300"
        >POLÍTICA DE PRIVACIDADE</a
      >
    </div>
  </footer>
</template>
<script setup></script>
<style></style>
