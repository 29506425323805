<template>
  <div class="relative">
    <!-- Seção do background parallax -->
    <div
      class="lg:bg-fixed bg-cover bg-no-repeat bg-center lg:h-[1000px] lg:mt-0"
      :style="{ backgroundImage: 'url(' + homeImage + ')' }"
    >
      <div
        class="flex flex-col justify-center align-center items-center h-full lg:pb-72"
      >
        <div
          class="flex text-center font-coolvetica-light tracking-wide text-white lg:text-9xl lg:py-0 py-40 text-4xl"
        >
          <span
            >Especialista em <br />
            deixar <span class="font-coolvetica">sua casa</span> <br />
            ainda mais <span class="font-qwenzy">elegante</span></span
          >
        </div>
        <button
          class="lg:text-3xl bg-[#393b3c] hover:bg-gradient-custom hover:text-black text-white font-bold mt-4 py-2 px-8 mb-8 lg:mb-0 rounded"
        >
          FALE CONOSCO
        </button>
      </div>
    </div>

    <div class="px-4 md:px-20 lg:px-40 py-24">
      <div class="pb-24 text-6xl md:text-7xl lg:text-8xl font-coolvetica-light">
        Nossos<br />
        Serviços
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          v-for="service in services"
          :key="service.id"
          class="bg-cover bg-center h-[600px] lg:h-[800px]"
          :style="{ backgroundImage: `url(${service.image})` }"
        >
          <!-- Sobreposição Escura para Texto -->
          <div
            class="flex flex-col justify-end h-full p-4 bg-black bg-opacity-20 hover:bg-opacity-50 transition duration-300 ease-in-out"
          >
            <h3 class="text-lg md:text-xl lg:text-2xl text-white font-semibold">
              {{ service.title }}
            </h3>
            <p class="text-sm md:text-base lg:text-lg text-white">
              {{ service.text }}
            </p>
            <!-- <button class="bg-white text-black mt-4 py-2 px-4 self-start">
              Saiba Mais
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row">
      <div class="md:flex flex-row">
        <div class="flex-initial w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
          <img
            src="../assets/images/home_169.png"
            alt="Imagem Descrição"
            class="w-full h-auto object-cover"
          />
        </div>
        <div class="bg-[#393b3c] p-4 lg:p-32 text-white flex-1">
          <div>
            <h2
              class="text-3xl md:text-4xl lg:text-8xl font-coolvetica-light mb-6"
            >
              Nossa <br />
              Missão
            </h2>
            <p class="text-sm md:text-base lg:text-xl mb-4">
              Transformando a soma de arquitetura e sofisticação em uma
              expressão de um lifestyle exclusivo. A combinação perfeita de
              inovação, qualidade impecável e atenção meticulosa aos detalhes,
              resultando em verdadeiras joias arquitetônicas.
            </p>
            <p class="text-sm md:text-base lg:text-xl">
              A <strong>arquitetura</strong> transcende
              <strong>paredes</strong> e <strong>materiais</strong>, tornando-se
              uma <strong>expressão de identidade, cultura e valores</strong>.
              Com dedicação, compreendemos as
              <strong>necessidades e desejos dos nossos clientes</strong>,
              traduzindo-os em <strong>projetos únicos e atemporais</strong>.
              Mais do que simplesmente criar estruturas, a arquitetura visa
              proporcionar uma <strong>experiência de vida inigualável</strong>,
              harmonizando <strong>estética e funcionalidade</strong> para
              oferecer <strong>excelência</strong> aos nossos clientes.
              <strong>Inovando e criando espaços singulares</strong>, o
              <strong>Studio 9</strong> é o seu parceiro em
              <strong>design sustentável</strong>.
              <strong>Sonhe, nós projetamos</strong>.
            </p>

            <div class="mt-8">
              <!-- Botões ou outros elementos aqui -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import homeImage from "../assets/images/home_image.png";
import interiorImage from "@/assets/services/interior_1.png";
import exteriorImage from "@/assets/services/exterior_1.png";
import exteriorImage2 from "@/assets/services/exterior_2.png";
import exteriorImage3 from "@/assets/services/exterior_3.png";
import comercialImage from "@/assets/services/comercial_1.png";
import executiveImage from "@/assets/services/executive_1.png";

const services = ref([
  {
    id: 1,
    title: "Design de Interiores",
    text: "Transformamos espaços residenciais e comerciais em lugares funcionais e esteticamente agradáveis. Cada projeto é uma oportunidade de criar harmonia entre forma e função.",
    image: interiorImage,
  },
  {
    id: 2,
    title: "Arquitetura Residencial",
    text: "Do sonho à realidade, projetamos casas que refletem a personalidade e o estilo de vida de nossos clientes. Cada detalhe é cuidadosamente pensado para proporcionar conforto e beleza.",
    image: exteriorImage,
  },
  {
    id: 3,
    title: "Arquitetura Comercial",
    text: "Seja um escritório, loja ou restaurante, criamos ambientes que cativam e envolvem. Acreditamos que o design pode impulsionar negócios e melhorar a experiência do cliente.",
    image: comercialImage,
  },
  {
    id: 4,
    title: "Acompanhamento de Obras",
    text: "Além de projetar, também acompanhamos a execução de cada projeto. Nossa equipe estará presente para garantir que a visão se torne realidade, supervisionando cada etapa com atenção aos detalhes.",
    image: exteriorImage2,
  },
  {
    id: 5,
    title: "Projetos Executivos",
    text: "Desenvolvemos projetos detalhados, com foco na viabilidade técnica e econômica.",
    image: executiveImage,
  },
  {
    id: 6,
    title: "Compatibilização de Projetos",
    text: "Garantimos a harmonia e integração entre diferentes disciplinas, evitando conflitos e otimizando a execução.",
    image: exteriorImage3,
  },
]);
</script>
